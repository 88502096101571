<template>
    <el-main>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>供片系统管理方</el-breadcrumb-item>
            <el-breadcrumb-item>模版路径1</el-breadcrumb-item>
            <el-breadcrumb-item>模版路径2</el-breadcrumb-item>
        </el-breadcrumb>

        <FixedSearchForm v-model="searchForm" :items="searchItems" @search="handleSearch" >
            <el-button type="primary" @click="editFormVisible=true">添加数据</el-button>
        </FixedSearchForm>
        <template>
            <el-table :data="tableData" stripe border size="small"
                       header-cell-class-name="table_header"
                      cell-class-name="table_cell" style="width: 100%">
                <el-table-column prop="vendor" label="设备方名称" min-width="100">
                </el-table-column>
                <el-table-column prop="companyname" label="公司名称">
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-tag :type="g_getVendorStatus(scope.row.status,true)"
                                disable-transitions>{{g_getVendorStatus(scope.row.status)}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column min-width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editHandle(scope.row.id)">
                            基本信息 <i class="el-icon-s-custom"></i>
                        </el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button type="text" @click="openEquips(scope.row.id)">
                            设备列表 <i class="el-icon-s-platform"></i>
                        </el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button v-if="scope.row.status === 0" type="text" @click="enableHandle(scope.row.id)">
                            启用 <i class="el-icon-open"></i>
                        </el-button>
                        <el-button v-if="scope.row.status === 1" type="text" @click="disableHandle(scope.row.id)">
                            停用 <i class="el-icon-turn-off"></i>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>

        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100]"
                :current-page="page"
                :page-size="limit"
                :total="total">
        </el-pagination>

        <el-dialog :title="editForm.id?'修改设备方':'添加设备方'" :visible.sync="editFormVisible" center :before-close="handleClose">
            <el-form :model="editForm" :rules="rules" ref="editForm">
                <el-descriptions class="margin-top"  :column="2"  border>
                    <el-descriptions-item>
                        <template slot="label">设备方</template>
                        <el-form-item :prop="vendor">
                            <el-input v-model="editForm.vendor"></el-input>
                        </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">联系人</template>
                        <el-form-item :prop="contact">
                            <el-input v-model="editForm.contact"></el-input>
                        </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">系统注册时间</template>
                        <el-date-picker v-model="editForm.sysregisterdate"
                                        align="right"
                                        type="date"
                                        value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-descriptions-item>
                </el-descriptions>
            </el-form>
            <el-button type="primary" @click="submitHandle('editForm')">保存</el-button>
            <el-button @click="resetForm('editForm')">取消</el-button>
        </el-dialog>
        <el-dialog title="子菜单" :visible.sync="menuVisible" center width="70%" :before-close="handleCloseSearchForm">
            <Menu_Template_Traditional :params="searchParams"></Menu_Template_Traditional>
        </el-dialog>
    </el-main>
</template>

<script>
    import FixedSearchForm from "../common/FixedSearchForm";
    import Menu_Template_Traditional from "./Menu_Template_Traditional";
    import {Search_Type} from "../../constants";
    const prefix = '/template';
    const getListUrl = `${prefix}/list`;
    const getOneUrl = (id)=>`${prefix}/one/${id}`;
    const postUrl = `${prefix}/save`;
    const putUrl = (id)=> `${prefix}/update/${id}`;
    const enableUrl = (id)=>`${prefix}/enable/${id}`;
    const disableUrl = (id)=>`${prefix}/disable/${id}`;
    export default {
        components: {FixedSearchForm,Menu_Template_Traditional},
        data() {
            return {
                tableData: [{
                    vendor: '',
                    companyname: '',
                    status: 0,
                }],
                page: 1,
                limit: 10,
                total: 0,
                editForm:{},
                searchParams: [],
                searchForm: {},
                searchItems:[
                    {
                        id: 1,
                        label: '设备方名称',
                        name: 'vendor',
                        type: Search_Type.Input,
                        default: true,
                    },
                    {
                        id: 3,
                        label: '状态',
                        name: 'status',
                        type: Search_Type.Select,
                        props: {
                            0: '停用',
                            1: '可用',
                        }
                    },
                ],
                rules:{
                    vendor: [
                        { required: true, message: '请输入设备方名称', trigger: 'blur'},
                        { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'blur' },
                        { pattern: /^\S.*\S$|(^\S{0,1}\S$)/,  message: '首尾不能为空格', trigger: 'blur' }
                    ],
                },
                editFormVisible: false,
                menuVisible: false,
            }
        },
        props:['params'],
        created() {
            if(!this.params){
                this.getTableData();
            }
        },
        methods: {
            getTableData(){
                this.$axios.get(getListUrl,{
                    params: {
                        ...this.g_getPage(this.page,this.limit),
                        ...this.searchForm
                    }
                }).then(res=>{
                    this.tableData = res.data.records;
                    this.page = res.data.current;
                    this.limit = res.data.size;
                    this.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.page = page;
                this.getTableData();
            },
            handleSearch(){
              this.tableOptions.page = 1;
              this.getTableData();
            },
            handleCloseSearchForm(done){
                this.searchParams = []
                done();
            },
            openMenu(id){
                this.menuVisible = true;
                this.searchParams = [{name:'id',value:id}];
            },
            editHandle(id){
                this.$axios.get(getOneUrl(id)).then(res=>{
                    this.editForm = res.data;
                    this.editFormVisible = true;
                });
            },
            enableHandle(id){
                this.$axios.put(enableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            disableHandle(id){
                this.$axios.put(disableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            submitHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.editForm.id){
                            this.$axios.put(putUrl(this.editForm.id),this.editForm
                            ).then(()=>{
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose:() => {
                                        this.getTableData()
                                    }
                                });
                                this.resetForm('editForm');
                            });
                        }else{
                            this.$axios.post(postUrl, this.editForm).then(()=>{
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose:() => {
                                        this.getTableData()
                                    }
                                });
                                this.editFormVisible = false
                            });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName){
                this.$refs[formName].resetFields();
                this.editFormVisible = false;
                this.editForm = {}
            },
            handleClose(){
                this.resetForm('editForm');
            }
        }
    }

</script>

<style scoped>
    table th a {
        background: none;
        color: #ffffff;
    }
    :deep(.el-form-item){
      margin-bottom: 0;
    }
</style>